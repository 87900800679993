<template>
  <div>
    <el-breadcrumb class="Bdh" separator-class="el-icon-arrow-right">
      <el-breadcrumb-item>聊天管理</el-breadcrumb-item>
      <el-breadcrumb-item>超时未联系</el-breadcrumb-item>
    </el-breadcrumb>
    <div class="add">
      <el-select
        v-model="choicekf"
        placeholder="请选择客服"
        style="width:150px;margin-right: 20px;"
        clearable
        filterable
        @clear="clear()"
        size="small"
      >
        <el-option v-for="item in options1" :key="item.id" :label="item.name" :value="item.id">
        </el-option>
      </el-select>
      <el-date-picker
        v-model="date"
        type="date"
        style="width:200px;margin-right: 20px;"
        placeholder="选择日期"
        format="yyyy-MM-dd"
        value-format="yyyy-MM-dd"
        size="small"
      >
      </el-date-picker>
      <el-button @click="query2()" type="primary" size="small">查询</el-button>
    </div>
    <el-table
      :data="list"
      border
      style="width: 100%"
      v-loading="showmore"
      element-loading-text="正在查询，请稍后..."
    >
      <el-table-column prop="id" label="手机号"></el-table-column>
      <el-table-column prop="name" label="用户名">
        <template slot-scope="scope">
          {{ scope.row.name == "" ? "暂无" : scope.row.name }}
        </template>
      </el-table-column>
      <el-table-column prop="desc" label="备注"></el-table-column>
      <el-table-column prop="serviceId" label="服务号">
        <template slot-scope="scope">
          {{ changeserviceid(scope.row.serviceId) }}
        </template>
      </el-table-column>
      <el-table-column prop="timeoutCount" label="超时未联系信息数"></el-table-column>
      <el-table-column>
        <template slot-scope="scope">
          <div class="opt">
            <el-button size="mini" type="primary" icon="el-icon-edit" @click="lookDetail(scope.row)"
              >查看</el-button
            >
          </div>
        </template>
      </el-table-column>
    </el-table>
    <el-dialog title="查看消息" :visible.sync="dialogFormVisible" width="80%">
      <p style="text-align:left;">共有{{ nocontactnum }}条超时未联系信息</p>
      <table class="looknocontentmsg">
        <tr>
          <!-- <td>发送人</td> -->
          <td>发送时间</td>
          <td>信息内容</td>
          <!-- <td>回复人</td> -->
          <td>回复时间</td>
          <td>回复内容</td>
          <td>超时时间</td>
        </tr>
        <tr v-for="item in talklist">
          <!-- <td>{{ item[1].from }}</td> -->
          <td>{{ item[1].sendTime }}</td>
          <td>{{ filtermsgtype(item[1]) }}</td>
          <!-- <td>{{ item[0] == null ? "" : item[0].from }}</td> -->
          <td>{{ item[0] == null ? "" : item[0].sendTime }}</td>
          <td>{{ item[0] == null ? "" : filtermsgtype(item[0]) }}</td>
          <td>
            {{ item[0] == null ? "超时未回复" : filterrtime(item[1].sendTime, item[0].sendTime) }}
          </td>
        </tr>
      </table>
    </el-dialog>
  </div>
</template>
<script>
import "../assets/talkStyle.css";
export default {
  data() {
    return {
      list: [],
      choicekf: "",
      options1: [],
      date: "",
      uid: sessionStorage.getItem("servicer_id"),
      dialogFormVisible: false,
      message: "",
      talklist: [],
      nocontactnum: "",
      showmore: false,
    };
  },
  watch: {
    date(newDate, oldDate) {
      if (newDate == null) {
        this.date = "";
      }
    },
  },
  mounted() {
    this.getkefulist();
    /*获取客服头像*/
    if (sessionStorage.getItem("headimg")) {
      this.headimg = sessionStorage.getItem("headimg");
    } else {
      this.headimg = this.female;
    }
  },
  methods: {
    clear() {
      this.choicekf = "";
    },
    query() {
      this.list = [];
      if (this.choicekf == "") {
        this.$message({
          type: "error",
          message: "请选择客服",
        });
        return;
      }
      if (this.date == "") {
        this.$message({
          type: "error",
          message: "请选择时间",
        });
        return;
      }
      this.axios
        .get(
          this.serveurl.serve +
            "/api/TimeoutNoContact/get_timeout_no_contact?serviceId=" +
            this.choicekf +
            "&day=" +
            this.date
        )
        .then((res) => {
          if (res.data.code == 1) {
            this.list = this.list.concat(res.data.result);
          }
        });
    },
    query1() {
      // this.showmore = true;
      this.list = [];
      if (this.date == "") {
        this.$message({
          type: "error",
          message: "请选择时间",
        });
        return;
      }

      var arr1 = [];
      var _this = this;
      for (var a = 0; a < _this.options1.length; a++) {
        var tmp = _this.options1[a];
        setTimeout(function() {
          arr1.push(_this.getallkefulist(tmp.id, tmp.serviceId));
        }, 1000);
      }
      arr1.forEach((element, index) => {
        element.a.then((res) => {
          if (res.data.result.length > 0) {
            var srr = res.data.result.forEach((item) => {
              item.serviceId = element.serviceId;
              this.list.push(item);
              // this.showmore = false;
            });
          } else {
            // this.showmore = false;
          }
        });
      });
      console.log(this.list);
    },
    getallkefulist(id, serviceId) {
      // this.showmore = true;

      return {
        serviceId: serviceId,
        a: this.axios.get(
          this.serveurl.serve +
            "/api/TimeoutNoContact/get_timeout_no_contact?serviceId=" +
            id +
            "&day=" +
            this.date
        ),
      };
      // .then((res) => {
      //   if (res.data.code == 1) {
      //     var arr = res.data.result;
      //     arr.forEach(element => {
      //       element.kefuid = this.options1[a].serviceId
      //     });
      //     console.log(arr)
      //     this.list = this.list.concat(arr);
      //   }
      //   if (a == j) {
      //     console.log("over");
      //     this.showmore = false;
      //   } else {
      //   }
      // });
    },
    query2() {
      this.list = [];
      if (this.date == "") {
        this.$message({
          type: "error",
          message: "请选择时间",
        });
        return;
      }
      if (this.choicekf != "") {
        console.log("选客服");
        this.axios
          .get(
            this.serveurl.serve +
              "/api/TimeoutNoContact/get_timeout_no_contact?serviceId=" +
              this.choicekf +
              "&day=" +
              this.date
          )
          .then((res) => {
            if (res.data.code == 1) {
              this.list = this.list.concat(res.data.result);
            }
          });
      } else {
        var arr1 = [];
        for (var a = 0; a < this.options1.length; a++) {
          arr1.push({ Id: this.options1[a].id, ServcieId: this.options1[a].serviceId });
        }
        console.log(arr1);
        var form = {
          day: this.date,
          list: JSON.stringify(arr1),
        };
        console.log(form);
        this.axios
          .post(
            this.serveurl.serve +
              "/api/TimeoutNoContact/get_timeout_no_contact_with_many_serviceId",
            this.qs.stringify(form)
          )
          .then((res) => {
            if (res.data.code == 1) {
              this.list = this.list.concat(res.data.result);
            }
          });
      }
    },
    getkefulist() {
      this.axios
        .get(this.serveurl.serve + "/api/Employee/get_employee?Id=" + this.uid)
        .then((res) => {
          if (res.data.code == 1) {
            this.options1 = res.data.result;
          }
        });
    },
    changeserviceid(val) {
      var str = "";
      for (let c = 0; c < this.options1.length; c++) {
        if (this.options1[c].id == val) {
          str = this.options1[c].serviceId;
        }
      }
      return str;
    },
    lookDetail(row) {
      this.dialogFormVisible = true;
      this.nocontactnum = row.timeoutCount;
      if (row.messages == "" || row.messages == null || row.messages == "null") {
        this.message = "暂无信息";
      } else {
        this.talklist = row.messages.reverse();
      }
    },
    filtermsgtype(val) {
      var str = "";
      switch (val.type) {
        case 0:
          str = val.content;
          break;
        case 1:
          str = "图片";
          break;
        case 2:
          str = "旧问诊单";
          break;
        case 3:
          str = "报名表";
          break;
        case 4:
          str = "推荐医生";
          break;
        case 5:
          str = "寻医表";
          break;
        case 6:
          str = "新问诊单";
          break;
        case 7:
          str = "直播连线";
          break;
        case 8:
          str = "挂号费";
          break;
        case 9:
          str = "订单";
          break;
        case 10:
          str = "好转病例";
          break;
        case 11:
          str = "短信";
          break;
        case 13:
          str = "反馈表";
          break;
        default:
          break;
      }
      return str;
    },
    filterrtime(time1, time2) {
      var date1 = new Date(time1); //开始时间
      var date2 = new Date(time2); //结束时间
      var date3 = date2.getTime() - date1.getTime(); //时间差的毫秒数
      //计算出相差天数
      var days = Math.floor(date3 / (24 * 3600 * 1000));
      //计算出小时数
      var leave1 = date3 % (24 * 3600 * 1000); //计算天数后剩余的毫秒数
      var hours = Math.floor(leave1 / (3600 * 1000));
      //计算相差分钟数
      var leave2 = leave1 % (3600 * 1000); //计算小时数后剩余的毫秒数
      var minutes = Math.floor(leave2 / (60 * 1000));
      //计算相差秒数
      var leave3 = leave2 % (60 * 1000); //计算分钟数后剩余的毫秒数
      var seconds = Math.round(leave3 / 1000);
      return days + "天 " + hours + "小时 " + minutes + " 分钟" + seconds + " 秒";
    },
  },
};
</script>
<style>
.add {
  width: 100%;
  height: 50px;
  text-align: left;
}

.opt a {
  cursor: pointer;
  color: green;
  margin-right: 20px;
  display: inline-block;
}
.Bdh {
  height: 50px;
  width: 100%;
  line-height: 40px;
}
.looknocontentmsg {
  width: 100%;
  border: 1px solid #ebeef5;
  border-collapse: collapse;
  border-spacing: 0;
}
.looknocontentmsg .el-dialog__body {
  overflow: hidden;
}
.looknocontentmsg tr,
.looknocontentmsg td {
  border: 1px solid #ebeef5;
  border-collapse: collapse;
  border-spacing: 0;
}
.looknocontentmsg tr {
  height: 40px;
}
</style>
